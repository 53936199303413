import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VanImage = _resolveComponent("VanImage")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_VanImage, {
      class: "mt-30px",
      width: "100%",
      height: "100%",
      src: _ctx.logo,
      "show-loading": ""
    }, null, 8, ["src"])
  ]))
}