
import { reactive, toRefs, onBeforeMount, onMounted } from 'vue'
import 'amfe-flexible'
import { Image as VanImage } from 'vant'
interface DataProps {}
export default {
  name: '',
  components: { VanImage },
  setup() {
    console.log('1-开始创建组件-setup')
    const data: DataProps = reactive({
      logo: require('./assets/detail.png')
    })
    onBeforeMount(() => {
      console.log('3.-组件挂载到页面之后执行-------onMounted')
    })
    onMounted(() => {
      console.log('3.-组件挂载到页面之后执行-------onMounted')
    })
    const refData = toRefs(data)
    return {
      ...refData
    }
  }
}
